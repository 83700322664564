import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { getFormData } from '~/helpers';
import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import useUser from '~/libs/use-user';
import { Products as ProductTypes } from '~/models/products.d';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import OESparePartRequest from '~/services/product/oe-spare-parts-request.service';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Label from '~/shared/form-elements/common/label/label.component';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';
import FileInput from '~/shared/form-elements/file-input/file-input.component';
import Input from '~/shared/form-elements/input/input.component';
import Radio from '~/shared/form-elements/radio/radio.component';
import Select from '~/shared/form-elements/select/select.component';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

const acceptedFileExtensions = ['.jpg', '.png', '.bmp', '.pdf', '.xls', '.xlsx', '.doc', '.docx', '.gif', '.zip', '.rar'];

export type IOEFormDataType = {
    OeType: ProductTypes.OeType;
    Subject: string;
    Files: string | string[];

    Contact: string;
    Email: string;
    CustomerNumber: string;
    CompanyName: string;
    Address: string;
    Department: string;
    PhoneNumber: string;

    RegNr: string;
    EngineCode: string;
    Vin: string;
    ModelName: string;
    FirstRegistration: string;
    LastInspection: string;
    ModelTypeName: string;
    ModelTypeId: string;
    ManufacturerName: string;
    IncludeUserInCc: boolean;

    ErrorCodes: string;
    Description: string;
    regNrOrVin: string;
};

interface IProps {
    vehicleDetails: VehicleTypes.IVehicleDetails;
    customerDetails?: {
        email: string;
        userName: string;
        customerId: number;
        companyName: string;
        phoneNumber: string;
        primaryDepartmentId: number;
        addresses: string[];
    };
}

const OEForm: FC<IProps> = ({ vehicleDetails = {}, customerDetails = {} }) => {
    const [fetching, setFetching] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();

    const shouldShowIncludeUserInCc = shouldShowFeature(features?.carBanner?.oeSparePartsRequestByEmail);

    const { isInternalUser, user } = useUser();
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const { locale } = useRouter();
    const {
        register,
        setError,
        clearErrors,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<IOEFormDataType>();

    const maxFilesSizeForEmail = files.map((file) => file.size).reduce((acc, cur) => acc + cur, 0);

    const emailSizeWarning = shouldShowIncludeUserInCc && maxFilesSizeForEmail >= 25 * 1024 * 1024; /* 25mb limit*/

    const disableSubmit = !termsAndConditions || emailSizeWarning;

    const onSubmit = async (postData: IOEFormDataType) => {
        if (disableSubmit) return;

        if (!postData.RegNr && !postData.Vin) {
            setError('regNrOrVin', {
                type: 'manual',
                message: translate('sharedFormTranslations.regNumberOrVin', 'Udfyld enten Stelnr. eller Reg. Nr'),
            });
            return;
        }

        setFetching(true);

        const formData = getFormData(postData);
        files.forEach((file) => formData.append('Files', file));

        try {
            const response = await OESparePartRequest(formData, locale as string, user);
            if (!response.errorMessage) {
                dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
                setFetching(false);
                window.setTimeout(() => {
                    window.close();
                }, 3000);
            } else {
                dispatch(addErrorToast(response.errorMessage));
            }
        } catch (error) {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        } finally {
            setFetching(false);
        }
    };

    const { licensePlate, engineCode, vin, typeYearFrom, typeYearTo, manufacturerName, modelName, modelTypeName, modelTypeId } = vehicleDetails;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore

    const { email, userName, customerId, companyName, phoneNumber, primaryDepartmentId, addresses } = customerDetails;

    const subjectErrorMessage = translate('oeSpareParts.validation.subject', 'Vælg venligst typen af din forespørgsel');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formGrid}>
                <div className={styles.col}>
                    <fieldset className={styles.caseType}>
                        <legend>
                            {translate('oeSpareParts.chooseWhatYouWant', 'Vælg hvad du vil')}
                            <span className={styles.requiredStar}>*</span>
                        </legend>
                        <div className={styles.radio}>
                            <Radio
                                onClick={() => setValue('OeType', ProductTypes.OeType.Oe)}
                                value={translate('oeSpareParts.oeRequest', 'OE-Forespørgsel')}
                                {...register('Subject', { required: subjectErrorMessage })}
                            >
                                {translate('oeSpareParts.oeRequest', 'OE-Forespørgsel')}
                            </Radio>
                        </div>
                        <div className={styles.radio}>
                            <Radio
                                onClick={() => setValue('OeType', ProductTypes.OeType.Order)}
                                value={translate('oeSpareParts.oeOrder', 'OE-Bestilling')}
                                {...register('Subject', { required: subjectErrorMessage })}
                            >
                                {translate('oeSpareParts.oeOrder', 'OE-Bestilling')}
                            </Radio>
                        </div>
                        <div className={styles.radio}>
                            <Radio
                                onClick={() => setValue('OeType', ProductTypes.OeType.Prices)}
                                value={translate('oeSpareParts.oePrices', 'OE-Priser (kræver reservedelsnr.)')}
                                {...register('Subject', { required: subjectErrorMessage })}
                            >
                                {translate('oeSpareParts.oePrices', 'OE-Priser (kræver reservedelsnr.)')}
                            </Radio>
                        </div>
                        <div className={styles.radio}>
                            <Radio
                                onClick={() => setValue('OeType', ProductTypes.OeType.Order)}
                                value={translate('oeSpareParts.other', 'Andet')}
                                {...register('Subject', { required: subjectErrorMessage })}
                            >
                                {translate('oeSpareParts.other', 'Andet')}
                            </Radio>
                        </div>
                        {errors?.Subject && <ValidationErrorMessage>{errors.Subject.message}</ValidationErrorMessage>}
                    </fieldset>
                </div>
                <div className={styles.col}>
                    <fieldset className={styles.caseType}>
                        <legend>{translate('sharedFormTranslations.uploadFiles', 'Upload filer')}</legend>
                        <Text textStyle="bodySmall">
                            {translate(
                                'oeSpareParts.uploadFileLimitations',
                                'Max 3 stk. i format .jpg, .png, .bmp, .pdf, .xls, .xlsx, .doc, .docx eller .gif. Må ikke fylde mere end 25 MB hver.',
                            )}
                        </Text>
                        <FileInput
                            errorMessage={
                                emailSizeWarning
                                    ? translate(
                                          'sharedFormTranslations.emailSizeWaring',
                                          'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                                      )
                                    : null
                            }
                            acceptedFileExtensions={acceptedFileExtensions}
                            accept={acceptedFileExtensions.join(', ')}
                            {...(shouldShowIncludeUserInCc ? null : { maxFilesNumber: 3 })}
                            maxFileSize={25 * 1024 * 1024 /* 25mb limit per file*/}
                            onFilesChange={setFiles}
                        />
                    </fieldset>
                </div>
            </div>

            <fieldset className={styles.fieldset}>
                <legend>
                    {isInternalUser
                        ? translate('oeSpareParts.myInfo', 'Mine oplysninger')
                        : translate('oeSpareParts.customerInfo', 'Kundeoplysninger')}
                </legend>
                <div className={styles.formGrid}>
                    <div className={styles.col}>
                        <Input
                            {...register('Contact')}
                            defaultValue={userName}
                            wrapperClassName={styles.textInput}
                            label={translate('customer.contactPerson', 'Kontaktperson')}
                        />
                        <Label required>{translate('customer.email', 'E-mail')}</Label>
                        <Input
                            className={styles.emailInput}
                            required
                            aria-label="email"
                            {...register('Email')}
                            type="email"
                            defaultValue={email}
                            wrapperClassName={styles.textInput}
                            errorMessage={errors?.Email ? errors?.Email?.message : ''}
                        />
                        <Input
                            {...register('CustomerNumber')}
                            defaultValue={customerId}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('customer.customerId', 'Kunde nr')}
                        />
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('CompanyName')}
                            readOnly
                            defaultValue={companyName}
                            wrapperClassName={styles.textInput}
                            label={translate('customer.companyName', 'Firmanavn')}
                        />
                        <Select {...register('Address')} label={translate('customer.address', 'Adresse')}>
                            {addresses?.map((addr) => (
                                <option value={addr} key={addr}>
                                    {addr}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('Department')}
                            readOnly
                            defaultValue={primaryDepartmentId}
                            wrapperClassName={styles.textInput}
                            label={translate('customer.department', 'Afdeling')}
                        />
                        <Input
                            {...register('PhoneNumber')}
                            defaultValue={phoneNumber}
                            wrapperClassName={styles.textInput}
                            label={translate('customer.phone', 'Telefon')}
                        />
                    </div>
                </div>
            </fieldset>

            <fieldset className={styles.fieldset}>
                <legend>{translate('oeSpareParts.caseInfo', 'Sagsoplysninger')}</legend>
                <div className={styles.formGrid}>
                    <div className={styles.col}>
                        <Label required>{translate('oeSpareParts.vehicleInfo', 'Vehicle Info')}</Label>
                        <fieldset className={styles.requiredCarInfo}>
                            <Input
                                {...register('RegNr')}
                                defaultValue={licensePlate?.number}
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.regNr', 'Reg nr.')}
                                onChange={() => clearErrors('regNrOrVin')}
                            />
                            <Input
                                {...register('Vin')}
                                defaultValue={vin}
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.chassisNumber', 'Stelnummer')}
                                onChange={() => clearErrors('regNrOrVin')}
                            />
                            {errors?.regNrOrVin ? <ValidationErrorMessage>{errors?.regNrOrVin.message}</ValidationErrorMessage> : null}
                        </fieldset>
                        <Input
                            {...register('EngineCode')}
                            defaultValue={engineCode}
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.engineCode', 'Motorkode')}
                        />
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('ModelName')}
                            defaultValue={modelName}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.modelName', 'Model')}
                        />
                        <div className={styles.dateRangeGrid}>
                            <Input
                                {...register('FirstRegistration')}
                                defaultValue={typeYearFrom}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.yearFrom', 'Fra årgang')}
                            />
                            <Input
                                {...register('LastInspection')}
                                defaultValue={typeYearTo}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.yearTo', 'Til årgang')}
                            />
                        </div>
                        <Input
                            {...register('ModelTypeName')}
                            defaultValue={modelTypeName}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.type', 'Type')}
                        />
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('ModelTypeId')}
                            readOnly
                            defaultValue={modelTypeId}
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.modelTypeId', 'FTZ Biltype nr')}
                        />
                        <Input
                            {...register('ManufacturerName')}
                            defaultValue={manufacturerName}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.manufacturerName', 'Mærke')}
                        />
                        <Input
                            {...register('ErrorCodes')}
                            wrapperClassName={styles.textInput}
                            label={translate('oeSpareParts.errorCodes', 'Fejlkoder')}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset className={styles.fieldset}>
                <div className={styles.formGrid}>
                    <div className={styles.descriptionCol}>
                        <TextArea
                            className={styles.description}
                            label={translate('common.description', 'beskrivelse')}
                            {...register('Description')}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset className={styles.fieldset}>
                {shouldShowIncludeUserInCc && (
                    <Checkbox {...register('IncludeUserInCc')}>
                        {translate(
                            'sharedFormTranslations.includeUserInCcCheckBox',
                            'Jeg ønsker selv at modtage en kopi af mailen (Mailen bliver sendt til den mail som du har udfyldt i "E-MAIL" filtet)',
                        )}
                    </Checkbox>
                )}
            </fieldset>
            <Text className={styles.formLegend}>{translate('common.fieldsMustBeFilledOut', 'Felter skal udfyldes')}</Text>
            <div className={styles.checkbox}>
                <Checkbox onChange={() => setTermsAndConditions(!termsAndConditions)} checked={termsAndConditions}>
                    {translate(
                        'oeSpareParts.termsAndConditions',
                        'Jeg accepterer at FTZ må gemme mine oplysninger i 6 måneder og andre vilkår som gør sig gældende for denne formular',
                    )}
                </Checkbox>
            </div>
            {errors?.Subject && <ValidationErrorMessage>{errors.Subject.message}</ValidationErrorMessage>}

            {errors?.Email && <ValidationErrorMessage>{errors.Email?.message}</ValidationErrorMessage>}

            {errors?.regNrOrVin && <ValidationErrorMessage>{errors?.regNrOrVin.message}</ValidationErrorMessage>}

            {emailSizeWarning && (
                <ValidationErrorMessage>
                    {translate(
                        'sharedFormTranslations.emailSizeWaring',
                        'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                    )}
                </ValidationErrorMessage>
            )}

            <Button disabled={disableSubmit} type="submit" className={styles.submitButton} fetching={fetching}>
                {translate('oeSpareParts.sendInquiry', 'Send forespørgsel')}
            </Button>
        </form>
    );
};

export default OEForm;
