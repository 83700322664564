import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const OESparePartRequest = (formData: FormData, locale: string, user: Session | null) => {
    return apiClient.auth(user).post(
        createUrl(
            {
                endpoint: endpoints.products.oeRequestPost,
                localeOption: LocaleOptions.path,
            },
            locale,
        ),
        {
            body: formData,
        },
    );
};

export default OESparePartRequest;
