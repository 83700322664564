import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import styles from './add-to-basket-quantity.module.scss';
import { AddToBasketQuantityButton } from './components/add-to-basket-quantity-button/add-to-basket-quantity-button';
import type { AddToBasketQuantityInputProps } from './components/add-to-basket-quantity-input/add-to-basket-quantity-input';
import { AddToBasketQuantityInputContainer } from './components/add-to-basket-quantity-input/add-to-basket-quantity-input.container';

export type AddToBasketQuantityProps = {
    inputValue: AddToBasketQuantityInputProps['value'];
    isLarge?: boolean;
    isAlwaysOpen?: boolean;
    onInputChange: (value: AddToBasketQuantityInputProps['value']) => void;
    onDecrementClick: () => void;
    onIncrementClick: () => void;
    inputRef: AddToBasketQuantityInputProps['inputRef'];
};

export function AddToBasketQuantity({
    inputValue,
    isLarge,
    isAlwaysOpen,
    onInputChange,
    onDecrementClick,
    onIncrementClick,
    inputRef,
}: AddToBasketQuantityProps) {
    const [inputHasFocus, setInputHasFocus] = useState(false);
    const wrapperRef = useOnclickOutside(() => {
        setInputHasFocus(false);
    });

    return (
        <div className={styles.addToBasketQuantityContainer} ref={wrapperRef}>
            {isAlwaysOpen || inputHasFocus ? (
                <AddToBasketQuantityButton ariaLabel={'decrement'} iconName={'minus'} isLarge={isLarge} onClick={onDecrementClick} />
            ) : null}

            <AddToBasketQuantityInputContainer
                initialValue={inputValue}
                isLarge={isLarge}
                onChange={onInputChange}
                setInputHasFocus={setInputHasFocus}
                inputRef={inputRef}
            />

            {isAlwaysOpen || inputHasFocus ? (
                <AddToBasketQuantityButton ariaLabel={'increment'} iconName={'plus'} isLarge={isLarge} onClick={onIncrementClick} />
            ) : null}
        </div>
    );
}
