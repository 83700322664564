import React, { useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { AnimatePresence, motion } from 'framer-motion';
import useEventListener from '../hooks/use-event-listener.hook';
import { Portal } from '~/shared/portal';
import styles from './light-box.module.scss';
import ButtonCircle from '../buttons/button-circle/button-circle.component';
import { useScrollLock } from '~/features/navigation/shared/hooks';

export interface ILightBoxProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose?: () => void;
}

export interface ILightBoxComponentProps extends ILightBoxProps {
    handleClose: () => void;
    closeOutside?: boolean;
    children: React.ReactNode;
}

const LightBox = ({ isOpen = false, onClose, ...rest }: ILightBoxProps) => (
    <AnimatePresence initial exitBeforeEnter onExitComplete={onClose}>
        {isOpen && <LightBoxComponent handleClose={onClose as () => void} isOpen {...rest} />}
    </AnimatePresence>
);

const LightBoxComponent = ({ children, isOpen, handleClose, closeOutside }: ILightBoxComponentProps) => {
    const handleEscKey = (evt: KeyboardEvent) => {
        if (evt.key === 'Escape') {
            handleClose();
        }
    };
    useEventListener('keydown', handleEscKey);

    const modalContentRef = useOnclickOutside(() => {
        if (isOpen && closeOutside) {
            handleClose();
        }
    });

    useScrollLock({
        locked: isOpen,
    });

    return (
        <Portal className={styles.root}>
            <motion.div className={styles.overlay} variants={backdropVariants} initial="closed" animate="open" exit="closed" transition={transition}>
                <motion.div
                    className={styles.modalWrapper}
                    variants={fullscreenVariants}
                    initial="closed"
                    animate="open"
                    exit="closed"
                    transition={transition}
                >
                    <ButtonCircle iconName="close" buttonStyle="dark" onClick={handleClose} className={styles.closeButton} />

                    <motion.div ref={modalContentRef} className={styles.modal}>
                        {children}
                    </motion.div>
                </motion.div>
            </motion.div>
        </Portal>
    );
};

const backdropVariants = {
    open: {
        opacity: 1,
    },
    closed: {
        opacity: 0,
    },
};

const fullscreenVariants = {
    open: {
        opacity: 1,
        scale: 1,
    },
    closed: {
        opacity: 0,
        scale: 0.1,
    },
};

const transition = {
    duration: 1000,
    type: 'spring',
    stiffness: 600,
    damping: 50,
};

export default LightBox;
